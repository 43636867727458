import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';

const Service = ({ data }) => {
  const StyledService = styled.div`
    .top-bg {
      height: 26rem;
      position: relative;
      background: #e4e4e4;
      .container {
        display: flex;
        justify-content: flex-end;
        height: 100%;
      }
      .title {
        width: 50%;
        position: absolute;
        bottom: 4rem;
        left: 0;
        h1 {
          width: 90%;
        }
      }
      .photo {
        width: 40%;
        height: 33rem;
        margin: 8rem 0;
        .gatsby-image-wrapper {
          height: 100%;
          width: 100%;
        }
      }
    }
    .lets-talk {
      background: ${props => props.theme.colors.org};
      text-decoration: none;
      color: #ffffff;
      margin: 5rem 0 10rem;
      display: inline-block;
      padding: 1.5rem 3rem;
      font-size: 1.6rem;
    }
    .content {
      width: 55%;
      font-family: ${props => props.theme.fonts.sec};
      color: ${props => props.theme.colors.grey};
      margin-top: 4rem;
      p {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
      ul li {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        color: #333333;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${props => props.theme.colors.org};
      }
      h1 {
        font-size: 2.5rem;
      }
      h2 {
        font-size: 2rem;
      }
      h3 {
        font-size: 1.9rem;
      }
      h4 {
        font-size: 1.8rem;
      }
      h5 {
        font-size: 1.7rem;
      }
      h6 {
        font-size: 1.6rem;
      }
    }
  `;
  return (
    <Layout>
      <StyledService>
        <div className="top-bg">
          <div className="container">
            <div className="title">
              <h1>{data.wordpressWpServices.acf.service_name}</h1>
            </div>
            <div className="photo">
              <Img
                fluid={
                  data.wordpressWpServices.acf.service_image.localFile
                    .childImageSharp.fluid
                }
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: data.wordpressWpServices.acf.service_content
            }}
          />
          <Link to="/contact" className="lets-talk">
            Let's Talk
          </Link>
        </div>
      </StyledService>
    </Layout>
  );
};

export const data = graphql`
  query($id: String!) {
    file(relativePath: { eq: "services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wordpressWpServices(id: { eq: $id }) {
      acf {
        service_name
        service_content
        service_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 950) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Service;
